<template>
  <v-app>
    <v-container
      id="login-account"
      fluid
      tag="section"
    >
      <v-row justify="center">
        Re-loading
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import { mapActions } from 'vuex'
  import router from '@/router'

  export default {
    name: 'LogouPage',

    components: {
    },

    mounted () {
      this.Logout()
    },
    methods: {
      ...mapActions([
        'logout',
      ]),
      Logout: function () {
        // localStorage.clear()
        this.$store.replaceState({})
        this.logout()
        // router.push('/login')
        router.go(0)
      },
    },

  }
</script>
